body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a.awsui_anchor_1kosq_101th_109{
  text-decoration: none !important;
}.container {
  display: flex;
}

@media (min-width: 400px) and (max-width: 500px) {
  .container {
    display: block;
  }
}

.drawerInventory{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 400px) and (max-width: 500px) {
  .drawer {
    display: block;
  }
}

.overview{
  display: flex;
 gap: 65px;
}
@media (min-width: 400px) and (max-width: 500px) {
  .overview {
    display: block;
  }
}
.info-row {
  display: contents; /* Maintain inline structure for each row */
}

.label {
  font-weight: normal;

}

.name, .items {
  font-weight: bold;
  justify-self: start; /* Align the value to the left */
}

.items {
  font-style: italic;
  justify-self: start; /* Align 16 Items to the left to reduce gap */
}


.otp-container {
  max-width: 400px;
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  text-align: center;
}

.otp-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.otp-subtext {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: center;
}

.highlight {
  color: #0073bb;
  font-weight: bold;
}

.otp-inputs {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.otp-input {
  width: 35px;
  height: 36px;
  font-size: 18px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #9BA7B6;
}

.otp-input:focus {
  border-color: #0073bb;
  outline: none;
  box-shadow: 0px 0px 5px rgba(0, 115, 187, 0.5);
}
.details {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px 20px; /* Control the gaps between the rows and columns */
  font-family: Arial, sans-serif;
  color: #333;
  width: 100%; /* Full width to fill container */
}

.info-row {
  display: contents; /* Allows the grid layout to keep consistent behavior */
}

.label {
  font-weight: normal;
  /* justify-self: end; */
}
.info-row .value {
  font-style: italic; /* Apply italic to all values */
  font-weight: 600;
  font-size: 12px ;
}

/* Remove italic for the first and second info-row values */
.info-row:nth-child(1) .value,
.info-row:nth-child(2) .value {
  font-style: normal;
  font-weight: 600;
  font-size: 12px ;
}




.items-list {
  grid-column: span 2; /* Spans across two columns */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.items-label {
  font-weight: bold;
  font-size: 16px;
}

.items-count {
  font-weight: normal;
  font-size: 12px;
  color: #666;
}





.product-card {
  display: flex;
  align-items: center;

}




.button-container {
  display: flex;
  gap: 15px;
  justify-content: end;
}

.cancel-btn, .print-btn {
  font-size: 12px;
  padding: 6px 15px;
  font-weight: 800;
  border-radius: 8px;
  cursor: pointer;
}

.cancel-btn {
  color: #D91515;
  background-color: transparent;
  border: 2px solid #D91515;
}

.cancel-btn:hover {
  background-color: rgb(247, 246, 246);
}

.print-btn {
  color: white;
  background-color: #0972D3;
  border: none;
}

.print-btn:hover {
  background-color: #0056b3;
}


.detail-row:last-child {
  margin-bottom: 0; /* Remove margin on the last row */
}
.orders-container {
  transition: filter 0.3s ease;
}

.blur-background {
  filter: blur(1px);
}

.drawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 30%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow-y: auto;
}
/* Additional styling for layout customization */
.order-item {
  padding: 8px 12px;
  background-color: #f9fafb; /* Slight gray background */
  border-radius: 4px;
  border: 1px solid #d1d5db; /* Border color */
  margin-bottom: 8px;
}
.runsheet-container{
  
  border-radius: 16px;

    box-shadow: 0px 0px 4px 0px #414D5C33, 0px 1px 14px 0px #00071624;
    padding: 25px;

}
.custom-table{
  margin-left: 10px;
}
.rider-info {
  font-family: Arial, sans-serif;
  color: #1a1a1a;
}

.info-item {
  display: flex;
  margin-bottom: 8px;
}

.label {

  margin-right: 16px; /* Increased spacing between label and value */
  min-width: 110px; /* Ensures consistent spacing across items */
}


.value {
  color: #000;
  font-weight: bold;
}
.section-header {
  font-weight: bold;
  /* margin-top: 16px; */
  margin-bottom: 8px;
}
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal {
  background: white;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: relative;
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.custom-modal-body {
  margin: 15px 0;
}



.image-container {
  display: flex;
  justify-content: center; /* Center images horizontally */
  align-items: center;    /* Center images vertically */
  gap: 10px;             /* Space between images */
}

.document-image {
  width: 100%;           /* Make images responsive */
  height: auto;         /* Maintain aspect ratio */
  max-width: 280px;     /* Limit the max width */
  border-radius: 5px;   /* Optional: Add rounded corners */
}
/* Hide the content by default */
.print-content {
  display: none;
}

/* Make it visible only during printing */
@media print {
  @page {
    size: 100mm ; /* Custom receipt size */
    margin: 10mm; /* Custom margin for printing */
  }
  body {
    background: none !important;
  }
  img {
    max-width: 45px; 
    height: 45px;
  }
  .print-content {
    display: block;
   
  }
}
